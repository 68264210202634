<template>
  <div
    :class="['archive-dates', { 'active' : this.activeSection }]"
    v-click-outside="hideSection"
  >
    <div class="head" @click="toggleSection">
      <div>Archive</div>
      <div>
        <img src="/images/icons/down-arrow.svg" :class="{ 'toggled' : this.activeSection }">
      </div>
    </div>
    <div :class="['toggle-section', { 'active' : this.activeSection }]">
      <hr/>
      <div class="periods">
        <div class="years">
          <div
            v-for="y in this.years"
            :key="y"
            @click="() => changeYear(y)"
            :class="{ 'active' : checkClass(y) }"
          >
            {{y}}
          </div>
        </div>
      </div>
      <hr/>
      <div class="months">
        <div
          v-for="m in this.months"
          :key="m"
          @click="() => changeMonth(m)"
          :class="{ 'active' : m === month }"
        >
          {{m}}
        </div>
      </div>
      <hr/>
      <div class="clear" @click="clearOptions">
        <span>Clear</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { monthsList } from '../data/constants';
  import ClickOutside from '../directives/clickOutside';
  export default {
    name: 'ArchiveDates',
    props: ['month', 'year'],
    data() {
      return {
        years: this.getYears(),
        months: this.getMonths(),
        activeSection: false,
      }
    },
    directives: {
      ClickOutside,
    },
    methods: {
      getYears() {
        const startBlogDate = 2020;
        const currentYear = new Date().getFullYear();
        const diff = currentYear - startBlogDate;
        if (!diff) {
          return [startBlogDate];
        } else {
          const years = ['All'];
          for (let i = 0; i <= diff; i++) {
            years.push(startBlogDate + i);
          }
          return years;
        }
      },
      getMonths() {
        return monthsList.map(month => month.substring(0, 3));
      },
      toggleSection() {
        this.activeSection = !this.activeSection;
      },
      hideSection: function() {
        this.activeSection = false;
      },
      changeMonth(month) {
        const value = month === this.month ? undefined : month;
        this.$emit('on-change-month', value);
      },
      changeYear(year) {
        const value = ['All', this.year].includes(year) ? undefined : year;
        this.$emit('on-change-year', value);
      },
      clearOptions() {
        this.$emit('on-change-month', undefined);
        this.$emit('on-change-year', undefined);
      },
      checkClass(year) {
        if (year === 'All') {
          return !this.year;
        } else {
          return year === this.year;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .archive-dates {
    color: black;
    width: 250px;
    padding: 10px;
    background: transparent;
    border-radius: 4px;
    z-index: 20;
    border: 1px solid transparent;

    &.active {
      background: $white;
      position: relative;
      border: 1px solid $black;
    }

    @media (max-width: $table-screen) {
      width: 250px;
    }

    @media (max-width: $mobile-screen) {
      width: 100%;
    }
  }

  .toggle-section {
    display: none;

    &.active {
      display: block;
      position: absolute;
      background: $white;
      left: -1px;
      width: calc(100% + 2px);
      padding: 0 10px 10px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border: 1px solid $black;
      border-top-color: transparent;
    }
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    @media (max-width: $table-screen) {
      justify-content: initial;
    }

    >* {
      cursor: pointer;
    }

    img {
      margin-left: 10px;
      width: 30px;
      height: 15px;
      display: inline-block;
      transition-duration: 0.5s;
      transition-property: transform;
      transform: rotate(0deg);

      &.toggled {
        transition-duration: 0.5s;
        transition-property: transform;
        transform: rotate(180deg);
      }
    }
  }

  .months >*{
    text-align: center;
  }

  .years >* {
    width: initial;
  }

  .months, .years {
    flex-wrap: wrap;
    display: flex;

    >* {
      flex-basis: 33%;
      text-align: center;
      cursor: pointer;
      padding: 10px 5px;

      &.active {
        background: $black;
        border-radius: 3px;
        color: $white;
      }
    }
  }

  .clear {
    text-align: center;
    cursor: pointer;
    
    span {
      background: $black;
      padding: 5px 10px;
      color: $white;
      border-radius: 17px;
    }
  }
</style>

