<template>
  <section>
    <headline text="Blog"/>
    <content-wrapper>
      <div class="searching">
        <search-input :query="searchQuery" @on-search-by-text="handleSearch"/>
        <div class="options">
          <div v-if="this.tags.length" class="tags-group">
            <div
                v-for="tag in this.tags"
                :key="tag"
                @click="() => handleClickTag(tag)"
                :class="['preview-tag', { 'active' : tag === activeTag }]"
            >
              {{tag}}
            </div>
          </div>
          <archive-dates
            :month="this.selectedMonth"
            :year="this.selectedYear"
            @on-change-year="handleChangeYear"
            @on-change-month="handleChangeMonth"
          />
        </div>
      </div>
      <loader v-if="this.loading"/>
      <p class="no-results" v-if="!this.posts.length && !this.loading">Ничего не найдено</p>
      <template v-if="!this.loading">
        <post
          v-for="post in this.posts"
          :post="post"
          :key="post.id"
          :isPreview="true"
        />
      </template>
    </content-wrapper>
  </section>
</template>

<script>
  import debounce from 'lodash.debounce';
  import Headline from '../components/Headline';
  import Post from '../components/Blog/Post';
  import ContentWrapper from '../components/Layout/ContentWrapper';
  import Loader from '../components/Layout/Loader';
  import SearchInput from '../components/Blog/SearchInput';
  import ArchiveDates from '../components/ArchiveDates';
  import { getRandomTags, showNotification } from '../data/services';
  export default {
    name: 'Blog',
    components: {
      ArchiveDates,
      Headline,
      Post,
      ContentWrapper,
      Loader,
      SearchInput,
    },
    data() {
      return {
        posts: [],
        tags: [],
        loading: false,
        searchQuery: '',
        activeTag: '',
        selectedYear: undefined,
        selectedMonth: undefined,
      }
    },
    methods: {
      async getTags() {
        try {
          // required additional request to prismic to get all tags
          const response = await fetch('https://danielsholden.cdn.prismic.io/api/v2');
          const meta = await response.json();
          this.tags = getRandomTags(meta.tags);
        } catch (e) {
          console.error(e);
        }
      },
      async getContent() {
        this.loading = true;
        function compare( a, b ) {
          const dateA = new Date(a.data?.date);
          const dateB = new Date(b.data?.date);
          return dateA < dateB ? 1 : (dateA > dateB ? -1 : 0);
        }
        try {
          const params = [
            this.$prismic.Predicates.at('document.type', 'blog'),
            this.$prismic.Predicates.fulltext('document', this.searchQuery),
          ];
          if (this.activeTag) {
            params.push(this.$prismic.Predicates.at('document.tags', [this.activeTag]));
          }
          if (this.selectedMonth) {
            params.push(this.$prismic.Predicates.month('my.blog.date', this.selectedMonth));
          }
          if (this.selectedYear) {
            params.push(this.$prismic.Predicates.year('my.blog.date', this.selectedYear));
          }
          const response = await this.$prismic.client.query(params);
          this.posts = response.results.sort( compare );
        } catch {
          showNotification('Something went wrong', 'error');
        } finally {
          this.loading = false;
        }
      },
      handleSearch(value) {
        this.searchQuery = value;
        this.debouncedSearch();
      },
      handleClickTag(tag) {
        this.activeTag = this.activeTag === tag ? '' : tag;
        this.getContent();
      },
      handleChangeYear(year) {
        this.selectedYear = year;
      },
      handleChangeMonth(month) {
        this.selectedMonth = month;
      },
      debouncedSearch: debounce(function () {
        this.getContent();
      }, 750)
    },
    created() {
      this.getTags();
      this.getContent();
    },
    watch: {
      selectedYear() {
        this.getContent();
      },
      selectedMonth() {
        this.getContent();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .head-line {
    padding-top: 50px;
  }
  .post {
    padding-bottom: 50px;
  }

  .search-box {
    margin-bottom: 40px;
  }

  .no-results {
    text-align: center;
  }

  .options {
    display: flex;
    margin-bottom: 50px;
    justify-content: space-between;
    align-items: baseline;

    .archive-dates {
      margin-left: auto;

      @media (max-width: $table-screen) {
        margin-left: initial;
      }
    }
  }

  .tags-group {
    color: $gray;
    display: flex;
    flex-wrap: wrap;
  }

  .preview-tag {
    padding: 7px 12px;
    margin: 0 5px 10px;
    background: #EFEFEF;
    border-radius: 4px;
    color: $gray;
    font-size: 14px;
    cursor: pointer;
  }

  @media (max-width: $table-screen) {
    .custom-loader {
      margin-top: 100px;
    }

    .options {
      display: block;
    }
  }
</style>