<template>
  <div class="search-box">
    <div class="search-input">
      <img src="/images/icons/search.svg" />
      <input
        @input="handleSearch"
        spellcheck="false"
        :value="this.query"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: ['query'],
  data() {
    return {

    };
  },
  methods: {
    handleSearch(e) {
      this.$emit('on-search-by-text', e.target.value);
    },
  },
}
</script>

<style lang="scss" scoped>
  .search-input {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-separator;
  }

  input {
    border-color: transparent;
    width: 100%;
    margin-left: 25px;
    color: $gray;
    letter-spacing: 0.05em;
    font-size: 16px;
  }
</style>
