<template>
  <h1 class="head-line">
    <span>{{text}}</span>
  </h1>
</template>

<script>
  export default {
    name: 'Headline',
    props: ['text'],
  }
</script>

<style lang="scss" scoped>
  .head-line {
    padding-top: 100px;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin: 0;

    span{
      display: inline-block;
      line-height: 30px;
      padding: 6px 12px 0;
    }
  }

  @media (max-width: $table-screen) {
    .head-line {
      padding: 0 0 30px;
    }
  }
</style>